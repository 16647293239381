import React from 'react'
import HeaderWrapper from '../../helper/PageWrapper'
import mobiscroll from '@mobiscroll/react'
import i18n from 'i18next'
import StaigeButton from '../../components/styles/Button'
import openInNewIcon from '../../img/icons/openInNew.svg'
import StaigeBadge from '../../components/styles/Badge'
import tagIcon from '../../img/icons/tag.svg'
import eyeIcon from '../../img/icons/eye.svg'
import editIcon from '../../img/icons/edit.svg'
import eventIcon from '../../img/icons/event.svg'
import scheduleIcon from '../../img/icons/schedule.svg'
import locatioOnIcon from '../../img/icons/location_on.svg'
import localActivityIcon from '../../img/icons/local_activity.svg'
import AiswSpinner from '../helper/aiswSpinner/aiswSpinner'
import StaigeSwitch from '../../components/styles/Switch'
import callToActionIcon from '../../img/icons/callToAction.svg'

import contractContainer, { ContractContainer } from '../../services/contractContainer'
import { Redirect } from 'react-router-dom'
import { VideoContainer } from '../../services/videoContainerContracts'
import { ClubContainer } from '../../services/clubContainer'
import { CameraContainer } from '../../services/cameraContainer'
import { NotFound } from '../NotFound/NotFound'
import { PaywallContainer } from '../../services/paywallContainer'
import { ClubData, InstreamTypes, Role, VideoData, PlayerOptions } from '@soccerwatch/common'
import { getRecordingStatus, RecordingStatus } from '../../helper/RecordingStatus'
import { UserContainer } from '../../services/userContainer'
import { TeamContainer } from '../../services/teamContainer'
import { CityContainer } from '../../services/cityContainer'
import { CompetitionContainer } from '../../services/competitionContainer'
import { checkLanguages } from '../helper/checkLanguages/checkLanguages'
import { searchAndSortGetRoles } from '../../services/serviceHelper'
import { AdvertisementContainer } from '../../services/advertisementContainer'

import './Recording.scss'
import StaigeModal from '../../components/styles/Modal'
import { EmbedCode } from './Container/embedCode'

type RecordingPropTypes = {
  id: number
  videoContainer: VideoContainer
  clubContainer: ClubContainer
  cameraContainer: CameraContainer
  paywallContainer: PaywallContainer
  userContainer: UserContainer
  teamContainer: TeamContainer
  cityContainer: CityContainer
  contractContainer: ContractContainer
  allDataLoaded: boolean
  competitionContainer: CompetitionContainer
  goBack?: string
  advertisementContainer: AdvertisementContainer
}

type RecordingState = {
  editId: unknown
  awaitPost: boolean
  deletedVideo?: unknown
  clubHome?: ClubData
  clubGuest?: ClubData
  video?: VideoData
  loading: boolean
  renderToggle: boolean
  player: any
  ads: Array<any>
  noAdsForThisVideo: boolean
  checkInterval: any
  isLiveRec: boolean
  isAfterRec: boolean
  embedCodeOpen: boolean
}

export default class Recording extends React.Component<RecordingPropTypes, RecordingState> {
  initialized = false
  playerContainerRef = React.createRef<any>()

  constructor(props: RecordingPropTypes) {
    super(props)

    this.state = {
      loading: true,
      editId: undefined,
      awaitPost: false,
      player: undefined,
      clubHome: undefined,
      clubGuest: undefined,
      video: undefined,
      renderToggle: false,
      ads: [],
      noAdsForThisVideo: true,
      checkInterval: undefined,
      isLiveRec: false,
      isAfterRec: false,
      embedCodeOpen: false
    }
  }

  private refStopRec = React.createRef<any>()
  private refFreeticketList = React.createRef<mobiscroll.PopupComponent>()

  async componentDidMount() {
    if (this.props.allDataLoaded && !this.initialized) {
      this.initialized = true
      await this.initialize()
    }
    this.props.contractContainer.setLockContractSelect(true)
  }

  componentWillUnmount = () => {
    this.props.contractContainer.setLockContractSelect(false)
    clearInterval(this.state.checkInterval)
  }

  componentDidUpdate = () => {
    if (this.props.allDataLoaded && !this.initialized) {
      this.initialized = true
      this.initialize()
    }

    if (this.playerContainerRef.current && !this.state.player) {
      this.createPlayer(String(this.props.id))
    }
  }

  handleResetInitialized = () => {
    this.initialized = false
  }

  createPlayer = (videoId: string) => {
    if (this.playerContainerRef.current) {
      // This is only the player element, not the actual instance with all the methods etc.
      const playerElement = document.createElement('aisw-player') as any
      // for callAs
      let callAs = searchAndSortGetRoles([
        Role.admin,
        contractContainer.getHighestRelevantRoleForCurrentContract()
      ])
      //

      playerElement.options = {
        gameId: videoId,
        volume: 0,
        autoplay: false,
        overlayControl: true,
        login: false,
        chat: false,
        defaultLanguage: checkLanguages(),
        callAs: callAs,
        adFree: true,
        paywallFree: true,
        events: false,
        embed: false,
        vrAllowed: true,
        presentationType: 'vr',
        enableMoreMedia: true,
        media: true
      }

      playerElement.addEventListener('hello', async (event: any) => {
        const actualPlayer = event.detail
        await actualPlayer.userService.firstAuthDone
      })

      this.playerContainerRef.current.append(playerElement as unknown as Node)
      this.setState({
        player: playerElement
      })
    }
  }

  initialize = async () => {
    let video: VideoData | undefined, clubHome, clubGuest
    await this.props.paywallContainer.setPayWallPackagesByMatchId(this.props.id)
    if (this.props.id) {
      await this.props.videoContainer.setVideoById(this.props.id)

      video = this.props.videoContainer
        .getVideosForCurrentContract()
        .find((video) => String(video.RowKey) === String(this.props.id))
      clubHome = await this.props.clubContainer.getClubById(String(video?.clubAId))
      clubGuest = await this.props.clubContainer.getClubById(String(video?.clubBId))
      const ads = this.props.advertisementContainer.getAdMaterialsForContract()

      let noAdsForThisVideo = true

      ads.map((ad) => {
        if (ad.enabledClubs?.find((z) => z === video?.RowKey)) {
          noAdsForThisVideo = false
        }

        if (ad.matchSponsor?.find((z) => z === video?.RowKey)) {
          noAdsForThisVideo = false
        }
      })

      const checkInterval = setInterval(() => {
        this.checkRecording()
      }, 5000)

      this.setState({
        video,
        clubHome,
        clubGuest,
        loading: false,
        ads,
        noAdsForThisVideo,
        checkInterval
      })

      this.checkRecording()
    }
  }

  changeVideoStatus = async (
    action:
      | 'cancelVideo'
      | 'revokeCancellation'
      | 'confirmFromImporter'
      | 'declineFromImporter'
      | 'activate'
      | 'deactivate'
  ) => {
    const video = this.props.videoContainer
      .getVideosForCurrentContract()
      .find((video) => String(video.RowKey) === String(this.props.id))

    if (video) {
      const currentStatus = getRecordingStatus(video)
      let actionMatchesState = true
      let modifiedVideo = {}

      if (
        (action === 'cancelVideo' && currentStatus === RecordingStatus.CREATED_MANUALLY_OR_CONFIRMED) ||
        (action === 'declineFromImporter' && currentStatus === RecordingStatus.IMPORTED)
      ) {
        modifiedVideo = { state: 'done', active: false }
      } else if (
        (action === 'revokeCancellation' &&
          currentStatus === RecordingStatus.CANCELLED_BUT_CAN_BE_REACTIVATED) ||
        (action === 'confirmFromImporter' && currentStatus === RecordingStatus.IMPORTED)
      ) {
        modifiedVideo = { state: 'created', active: true }
      } else if (action === 'activate' && currentStatus === RecordingStatus.RECORDED_BUT_INACTIVE) {
        modifiedVideo = { active: true }
      } else if (action === 'deactivate' && currentStatus === RecordingStatus.RECORDED_AND_ACTIVE) {
        modifiedVideo = { active: false }
      } else {
        actionMatchesState = false
      }

      if (actionMatchesState) {
        const currentContract = this.props.contractContainer.getCurrentContract()
        modifiedVideo = {
          ...modifiedVideo,
          RowKey: video.RowKey,
          contractId: video.contractId || currentContract?.RowKey
        }
        //@ts-ignore
        await this.props.videoContainer.updateVideo(modifiedVideo)
        await this.initialize()
      } else {
        throw new Error(
          `Desired action (${action}) cannot be performed on the current state (${RecordingStatus[currentStatus]})!`
        )
      }
    }
  }

  showFreeTicketList = () => {
    this.refFreeticketList.current?.instance.show()
  }

  renderLinkContainer = (active: boolean, text: string, href: string, openText: string | undefined) => {
    // default for openText
    openText = openText ? openText : i18n.t('recording.open')

    if (!active) {
      return null
    }

    return (
      <div className='linkContainer box'>
        <div className='text'>{text}</div>
        <div>
          <a href={href} target='noopener noreferrer'>
            <mobiscroll.Button flat>{openText}</mobiscroll.Button>
          </a>
        </div>
      </div>
    )
  }

  handleStopRecording = () => {
    const body = {
      RowKey: String(this.props.id),
      // state: 'Eliminate Clone',
      stopRequested: true
    }

    this.props.videoContainer.updateVideoStopRecording(body)

    mobiscroll.alert({
      title: i18n.t('recording.instantStop'),
      message: i18n.t('recording.instantStopMessage')
    })
  }

  renderInfoTextBox(icon: string, text: string) {
    return (
      <div className='box-textBox'>
        <div className='textBox-icon'>
          <img src={icon} />
        </div>
        <div className='textBox-text'>{text}</div>
      </div>
    )
  }

  dateBetweenCheck(from: any, to: any, check: any) {
    let fDate, lDate, cDate
    fDate = Date.parse(from)
    lDate = Date.parse(to)
    cDate = Date.parse(check)

    if (cDate <= lDate && cDate >= fDate) {
      return true
    }
    return false
  }

  dateAfterCheck(from: any, check: any) {
    let fDate, cDate
    fDate = Date.parse(from)
    cDate = Date.parse(check)

    if (cDate >= fDate) {
      return true
    }
    return false
  }

  handlePrivateActive = async (RowKey: string, privateActive: boolean) => {
    const state = privateActive
      ? { privateActive: false, platformIgnore: false }
      : { privateActive: true, platformIgnore: true }
    const modifiedVideo = {
      ...state,
      RowKey: RowKey
    }
    //@ts-ignore
    await this.props.videoContainer.updateVideo(modifiedVideo)
    const massage = privateActive
      ? 'Das Event ist nun für alle auf deiner staige.tv-Seite sichtbar'
      : 'Das Event auf staige.tv kann nur über einen privaten Link aufgerufen werden'
    await mobiscroll.toast({
      message: massage,
      display: 'top'
    })
    await this.initialize()
  }

  checkRecording = () => {
    const { video } = this.state

    if (!video) return
    const expectedStartTime = new Date(video.expectedStartTime)
    const isLiveRec = this.dateBetweenCheck(
      new Date(video.expectedStartTime),
      new Date(expectedStartTime.getTime() + video.durationMin * 60000),
      new Date()
    )
    const isAfterRec = this.dateAfterCheck(
      new Date(expectedStartTime.getTime() + video.durationMin * 60000),
      new Date()
    )

    this.setState({
      isLiveRec,
      isAfterRec
    })
  }

  render() {
    const goBackToLink = this.props.goBack ? '/recordingplan' + this.props.goBack : '/recordingplan'
    const { clubHome, clubGuest, video, isLiveRec, isAfterRec } = this.state

    if (this.state.deletedVideo) {
      return <Redirect push to='/recordingplan' />
    }

    if (this.state.loading) {
      return (
        <HeaderWrapper headerTitle={'Zurück zum Kalender'} linkTo={goBackToLink} style={{ height: '100%' }}>
          <div style={{ height: '100%' }}>
            <AiswSpinner />
          </div>
        </HeaderWrapper>
      )
    }

    if (!video) {
      return <NotFound nameOfItem={i18n.t('recording.popupHeaderText')} linkTo={goBackToLink} />
    }
    if (!clubHome) {
      return <NotFound nameOfItem={i18n.t('recordingPlan.teams.homeSelectTitle')} linkTo={goBackToLink} />
    }
    if (!clubGuest) {
      return <NotFound nameOfItem={i18n.t('recordingPlan.teams.guestSelectTitle')} linkTo={goBackToLink} />
    }

    const expectedStartTime = new Date(video.expectedStartTime)
    const splittedFrom = expectedStartTime.toLocaleString().split(' ')[1].split(':').splice(0, 2)
    const splittedTo = new Date(expectedStartTime.getTime() + video.durationMin * 60000)
      .toLocaleString()
      .split(' ')[1]
      .split(':')
      .splice(0, 2)
    const expectedStartResult = splittedFrom.join(':') + ' - ' + splittedTo.join(':')

    return (
      <HeaderWrapper headerTitle={'Zurück zum Kalender'} linkTo={goBackToLink}>
        <StaigeModal
          ref={this.refStopRec}
          onConfirm={() => {
            this.handleStopRecording()
          }}
          confirmText={i18n.t('recording.stopRecModal.confirmText')}
          title={i18n.t('recording.stopRecModal.title')}
          text={i18n.t('recording.stopRecModal.text')}
        />
        <div className='page-event'>
          <div className='event-content'>
            <div className='content-box'>
              {isAfterRec ? (
                <div className='box-headerAf'>
                  <div className='teams-top'>
                    <div className='top-logos'>
                      <div className='club-logo'>
                        <img
                          src={
                            clubHome?.thumbnail ||
                            'https://storage.googleapis.com/sw-sc-de-assets/assets/assets_clubq.png'
                          }
                        />
                        <div className='bg' style={clubHome.color ? { background: clubHome.color } : {}} />
                      </div>
                      <div className='club-logo' style={{ marginLeft: '-16px' }}>
                        <img
                          style={{ zIndex: 3 }}
                          src={
                            clubGuest?.thumbnail ||
                            'https://storage.googleapis.com/sw-sc-de-assets/assets/assets_clubq.png'
                          }
                        />
                        <div className='bg2' />
                        <div
                          className='bg'
                          style={clubGuest.color ? { background: clubGuest.color, zIndex: 3 } : { zIndex: 3 }}
                        />
                      </div>
                    </div>
                    <div className='top-quickInfo'>
                      <StaigeSwitch
                        size='medium'
                        label={i18n.t('general.public')}
                        checked={!video.privateActive}
                        onClick={() => this.handlePrivateActive(video.RowKey, Boolean(video.privateActive))}
                      />
                      <StaigeButton
                        style='ghost'
                        icon={editIcon}
                        iconMargin='0 -4px 0 -4px'
                        linkTo={'/recordingplan/edit/' + video.RowKey}
                      />
                    </div>
                  </div>
                  <div className='teams-title'>
                    {clubHome?.name} {video.scoreA || '0'}-{video.scoreB || '0'} {clubGuest?.name}
                  </div>
                  <div className='teams-label'>
                    <StaigeBadge active={video.contentType === 'broadcast'} color='violet' title={`event`} />
                    <StaigeBadge active={video.contentType === 'training'} color='white' title={`training`} />
                    <StaigeBadge active={video.contentType === 'test'} color='white' title={`test`} />
                    <StaigeBadge active={video?.teamAAge !== undefined} title={video.teamAAge} />
                  </div>
                </div>
              ) : (
                <div className='box-header'>
                  <StaigeBadge
                    active={video.contentType === 'broadcast'}
                    color='red'
                    title={`${isLiveRec ? 'live event' : 'event'}`}
                  />
                  <StaigeBadge
                    active={video.contentType === 'training'}
                    color='white'
                    title={`${isLiveRec ? 'live training' : 'training'}`}
                  />
                  <StaigeBadge
                    active={video.contentType === 'test'}
                    color='white'
                    title={`${isLiveRec ? 'live test' : 'test'}`}
                  />
                  <StaigeBadge active title={video.privateActive ? 'Privat' : 'Öffentlich'} />
                </div>
              )}

              <div className='box-content'>
                <div className={isAfterRec ? 'content-share content-shareAf' : 'content-share'}>
                  <div className='share-left'>
                    {this.props.paywallContainer.state.paywallFreeTickets.length > 0 ? (
                      <a className='left-freeCard' onClick={() => this.showFreeTicketList()}>
                        {i18n.t('recording.freecard')}
                      </a>
                    ) : null}
                  </div>
                  <div className='share-quickBtn'>
                    <StaigeButton
                      style='ghost'
                      label={'Embed Code'}
                      onClick={() => {
                        this.setState({ embedCodeOpen: !this.state.embedCodeOpen })
                      }}
                    />
                    <StaigeButton
                      style='ghost'
                      label={i18n.t('recording.staigeLink')}
                      linkTo={'https://staige.tv/video/' + video.RowKey + '/'}
                      externLink
                      icon={openInNewIcon}
                      iconMargin='0 -4px 0 0'
                      iconRight
                      disabled={!video.active}
                      target='_blank'
                    />
                    <StaigeButton
                      style={isLiveRec ? 'ghost' : 'secondary'}
                      label={i18n.t('recording.tagtool')}
                      linkTo={'/tagging/' + video.RowKey}
                      icon={openInNewIcon}
                      iconMargin='0 -4px 0 0'
                      iconRight
                    />

                    {isLiveRec && video.active ? (
                      <div className='quickBtn-stopRec'>
                        <StaigeButton
                          style='danger-secondary'
                          label={i18n.t('recording.stopRecTitle')}
                          onClick={() => this.refStopRec.current.handleOpenModal()}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>

                <EmbedCode active={this.state.embedCodeOpen} id={video.RowKey} />

                {!isAfterRec ? (
                  <div className='content-preview'>
                    <div className='preview-club'>
                      <div className='club-logo'>
                        <img
                          src={
                            clubHome?.thumbnail ||
                            'https://storage.googleapis.com/sw-sc-de-assets/assets/assets_clubq.png'
                          }
                        />
                        <div className='bg' style={clubHome.color ? { background: clubHome.color } : {}} />
                      </div>
                      <div className='club-name'>{clubHome?.name}</div>
                    </div>
                    <div className='preview-score'>
                      <div className='score-badges'>
                        <StaigeBadge active title={video.teamAAge} />
                      </div>
                    </div>
                    <div className='preview-club'>
                      <div className='club-logo'>
                        <img
                          src={
                            clubGuest?.thumbnail ||
                            'https://storage.googleapis.com/sw-sc-de-assets/assets/assets_clubq.png'
                          }
                        />
                        <div className='bg' style={clubGuest.color ? { background: clubGuest.color } : {}} />
                      </div>
                      <div className='club-name'>{clubGuest?.name}</div>
                    </div>
                  </div>
                ) : (
                  <div style={{ height: 8 }} />
                )}

                {
                  //@ts-ignore Types dont seem to have calculated States
                  (video.state === 'done' || video.state === 'Mission running' || video.state === 'live') &&
                  video.userStream ? (
                    <div className='content-player' ref={this.playerContainerRef} />
                  ) : null
                }

                {isLiveRec && video.active ? (
                  <div className='content-stopRec'>
                    <StaigeButton
                      style='danger-secondary'
                      label={i18n.t('recording.stopRecTitle')}
                      onClick={() => this.refStopRec.current.handleOpenModal()}
                    />
                  </div>
                ) : null}

                <div className='content-mediaInfo'>
                  <div className='mediaInfo-box'>
                    <div className='box-title'>Event Zusammenfassung</div>
                    {this.renderInfoTextBox(tagIcon, video.RowKey)}
                    {this.renderInfoTextBox(eyeIcon, video.privateActive ? 'Privat' : 'Öffentlich')}
                    {this.renderInfoTextBox(
                      eventIcon,
                      new Date(video.expectedStartTime).toLocaleDateString()
                    )}
                    {this.renderInfoTextBox(scheduleIcon, expectedStartResult)}
                    {this.renderInfoTextBox(locatioOnIcon, video.address || video.field || 'unbekannt')}
                    {this.renderInfoTextBox(
                      localActivityIcon,
                      video.paywall ? i18n.t('recording.paywall') : i18n.t('recording.free')
                    )}
                  </div>

                  {video.contentType === 'broadcast' && !this.state.noAdsForThisVideo ? (
                    <div className='mediaInfo-box'>
                      <div className='box-title'>Sponsoren</div>
                      {this.state.ads.map((ad, i) => {
                        if (ad.enabledClubs?.find((ad: string) => ad === video?.RowKey)) {
                          return (
                            <div className='box-adItem' key={i}>
                              a
                            </div>
                          )
                        }

                        if (ad.matchSponsor?.find((ad: string) => ad === video?.RowKey)) {
                          return (
                            <div className='box-adItem' key={i}>
                              <div className='adItem-img'>
                                <img src={ad.mediaInstream} />
                              </div>
                              <div className='adItem-content'>
                                <div className='content-title'>{ad.name}</div>
                                <div className='content-info'>
                                  <StaigeBadge
                                    active={ad.enabledClubs && ad.enabledClubs?.length > 0}
                                    title={i18n.t('sponsors.badges.clubBased')}
                                  />

                                  <StaigeBadge
                                    active={ad.enabledClubs && ad.enabledClubs?.length === 0}
                                    title={i18n.t('sponsors.badges.gameBased') + ' '}
                                    color='rosa'
                                  />

                                  <div className='banner-containerText'>
                                    <div className='containerText-icon'>
                                      <img src={callToActionIcon} />
                                    </div>
                                    <div className='containerText-text'>
                                      {ad.instreamType === InstreamTypes.IMAGE &&
                                        i18n.t('editAdvertisement.labels.loop')}
                                      {ad.instreamType === InstreamTypes.STATIC &&
                                        i18n.t('editAdvertisement.labels.fixed')}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        }
                        return <div className='box-bottomLine' key={i} />
                      })}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        <mobiscroll.Popup
          ref={this.refFreeticketList}
          display='center'
          buttons={[{ text: i18n.t('general.cancel'), handler: 'cancel' }]}
        >
          <div className='mbsc-padding'>
            <div className='mbsc-align-center'>
              <h3>{i18n.t('recording.freecard')}</h3>
            </div>

            {this.props.paywallContainer.state.paywallFreeTickets.length > 0
              ? this.props.paywallContainer.state.paywallFreeTickets.map((itm, i) => {
                  const number = i + 1
                  return (
                    <div
                      key={i}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '20px',
                        backgroundColor: '#0000001C',
                        marginBottom: '2px'
                      }}
                    >
                      {i18n.t('recording.ticket')}{' '}
                      {number.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}:{' '}
                      <p style={{ paddingLeft: '10px' }}>{itm['Trans-Nr']}</p>
                    </div>
                  )
                })
              : null}
            <div style={{ width: '250px' }} />
          </div>
        </mobiscroll.Popup>
      </HeaderWrapper>
    )
  }
}
