import React, { Component } from 'react'
import i18n from '../../../languages/i18n'
import TimeMenu from './TimeMenu'
import ClubScoreInfo from './ClubInfo'
import EventList from './EventList'

import { checkLanguages } from '../../helper/checkLanguages/checkLanguages'
import { RouteComponentProps } from 'react-router-dom'
import { withRouter } from 'react-router'
import { ClubData, Tag } from '@soccerwatch/common'
import { EVENTSETTINGS } from './eventSettings'

import insertIcon from '../../../img/icons/insert.svg'

type EventContainerPropsType = RouteComponentProps & {
  clubs:
    | {
        home: ClubData
        guest: ClubData
      }
    | undefined
  tags: Array<any>
  createTag: (
    eventType: number,
    extraBody?: any,
    secondCurrentTime?: number,
    removeTimerDisabled?: boolean
  ) => void
  postTag: (body: any, matchId: string, tagId?: string, removeTimerDisabled?: boolean) => void
  playerCurrentTime: number
  videoType: string
  timerInformation: { timer: string; minutes: number; seconds: number }
  handleStartStopPlayer: (type: 'play' | 'pause') => void
  score: { home: number; guest: number }
  videoInfo: any
  handleEventContainerTagging: { value: boolean; handleChange: (value: boolean) => void }
  handleSeekTo: (time: number) => void
  reloadTags: () => void
  popupJSX: any
  handleOpenCloseInsert: () => void
  liveTagging: boolean
  lastNewTag?: { id: string; timer: number; eventType: any }
  deleteTag: () => void
  waitDelete: boolean
}

type EventContainerStateType = {
  scrollTop: boolean
}

class EventContainer extends Component<EventContainerPropsType, EventContainerStateType> {
  checkAlertRef = React.createRef<any>()

  constructor(props: EventContainerPropsType) {
    super(props)
    this.state = {
      scrollTop: false
    }
  }

  renderEvents = () => {
    return this.renderEventButtonDouble()
  }

  renderRemoveButton = (active: boolean) => {
    if (!active) return
    return (
      <a
        style={{ marginRight: '-4px', fontSize: '18px', position: 'relative' }}
        onClick={(event) => {
          this.props.deleteTag()
          event.stopPropagation()
        }}
      >
        <div className='smallIcon-button' style={{ position: 'absolute', right: 0 }}>
          <span className='mbsc-ic mbsc-ic-ion-arrow-return-left' />
        </div>
      </a>
    )
  }

  renderEventButtonDouble = () => {
    const { tags } = this.props

    // event TimeOut
    let timeoutButton: any = []
    if (EVENTSETTINGS[this.props.videoType].eventTimeOut) {
      //@ts-ignore
      const eventTimeOut = EVENTSETTINGS[this.props.videoType].eventTimeOut.start
      //@ts-ignore
      const eventTimeOutEnd = EVENTSETTINGS[this.props.videoType].eventTimeOut.end
      const name = checkLanguages() === 'de' ? eventTimeOut.name : eventTimeOut.nameEn

      let homeDisabled = false,
        guestDisabled = false
      let homeCounter = 0,
        guestCounter = 0

      let refTags: Array<Tag> = JSON.parse(JSON.stringify(tags))
      refTags = refTags.filter(
        (tag) =>
          tag.eventType === eventTimeOut.eventTypes[0] ||
          tag.eventType === eventTimeOut.eventTypes[1] ||
          tag.eventType === eventTimeOutEnd.eventType.id
      )

      refTags.forEach((tag, index) => {
        // Home Club
        if (tag.eventType === eventTimeOut.eventTypes[0]) {
          homeCounter++
          // check max timeouts
          if (homeCounter === eventTimeOut.maxTimeouts) {
            homeDisabled = true
          }

          // check set eventTimeOutEnd
          const nextTag = refTags[index + 1]
          const isNextTagMatching = nextTag && nextTag.eventType === eventTimeOutEnd.eventType.id

          if (!isNextTagMatching) {
            homeDisabled = true
            guestDisabled = true
          }
        }

        // Guest Club
        if (tag.eventType === eventTimeOut.eventTypes[1]) {
          guestCounter++
          // check max timeouts
          if (guestCounter === eventTimeOut.maxTimeouts) {
            guestDisabled = true
          }
        }
      })

      const button = (
        <div key={'-z'} className='grid-double-btn'>
          <button
            className={homeDisabled ? 'timeout-btnDisabled' : 'timeout-btn'}
            disabled={this.props.waitDelete}
            onClick={() => {
              if (homeDisabled) return
              this.props.createTag(eventTimeOut.eventTypes[0], eventTimeOut.body)
            }}
          >
            <div className={'tag-name'}>{name}</div>
          </button>
          <button
            className={guestDisabled ? 'timeout-btnDisabled' : 'timeout-btn'}
            disabled={this.props.waitDelete}
            onClick={() => {
              if (guestDisabled) return
              this.props.createTag(eventTimeOut.eventTypes[1], eventTimeOut.body)
            }}
          >
            <div className={'tag-name'}>{name}</div>
          </button>
        </div>
      )
      timeoutButton = [button]
    }

    const buttons = EVENTSETTINGS[this.props.videoType].eventButtons.double.map((itm, index) => {
      const name = checkLanguages() === 'de' ? itm.name : itm.nameEn
      let counterHome = 0
      let counterGuest = 0
      let removeHome = false
      let removeGuest = false
      tags.forEach((tag) => {
        if (tag.eventType === itm.eventTypes[0]) {
          counterHome++
          if (
            this.props.lastNewTag !== undefined &&
            this.props.lastNewTag.eventType.eventType === tag.eventType
          ) {
            removeHome = true
          }
        }

        if (tag.eventType === itm.eventTypes[1]) {
          counterGuest++
          if (
            this.props.lastNewTag !== undefined &&
            this.props.lastNewTag.eventType.eventType === tag.eventType
          ) {
            removeGuest = true
          }
        }
      })

      return (
        <div key={index + '-l'} className='grid-double-btn'>
          <button
            className='tag-btn'
            disabled={this.props.waitDelete}
            onClick={() => this.props.createTag(itm.eventTypes[0], itm.body)}
          >
            <div className={'tag-name'}>
              {name}
              {this.renderRemoveButton(removeHome)}
            </div>
            <div className={'tag-counter'}>{counterHome}</div>
          </button>
          <button
            className='tag-btn'
            disabled={this.props.waitDelete}
            onClick={() => this.props.createTag(itm.eventTypes[1], itm.body)}
          >
            <div className={'tag-name'}>
              {name}
              {this.renderRemoveButton(removeGuest)}
            </div>
            <div className={'tag-counter'}>{counterGuest}</div>
          </button>
        </div>
      )
    })

    const buttonsSolo = EVENTSETTINGS[this.props.videoType].eventButtons.single.map((itm, index) => {
      const name = checkLanguages() === 'de' ? itm.name : itm.nameEn
      let counter = 0
      let removeActive = false

      tags.forEach((tag) => {
        if (tag.eventType === itm.eventTypes) {
          counter++
          if (
            this.props.lastNewTag !== undefined &&
            this.props.lastNewTag.eventType.eventType === tag.eventType
          ) {
            removeActive = true
          }
        }
      })

      return (
        <div key={index + '-z'} className='grid-double-btn' style={{ gridTemplateColumns: 'repeat(1, 1fr)' }}>
          <button
            className='tag-btn'
            disabled={this.props.waitDelete}
            onClick={() => this.props.createTag(itm.eventTypes, itm.body)}
          >
            <div className={'tag-name'}>
              {name}
              {this.renderRemoveButton(removeActive)}
            </div>
            <div className={'tag-counter'}>{counter}</div>
          </button>
        </div>
      )
    })

    return timeoutButton
      .concat(buttons)
      .concat(buttonsSolo)
      .filter((itm: any) => itm !== null)
  }

  onScroll = (event: any) => {
    if (event.currentTarget.scrollTop > 0 && this.state.scrollTop === false) {
      this.setState({
        scrollTop: true
      })
    } else if (event.currentTarget.scrollTop === 0 && this.state.scrollTop === true) {
      this.setState({
        scrollTop: false
      })
    }
  }

  render() {
    const { handleEventContainerTagging } = this.props
    return (
      <div className='event-container'>
        {this.props.children ? null : (
          <div className='container-switch'>
            <div
              className={handleEventContainerTagging.value ? 'switch-btn active' : 'switch-btn inactive'}
              onClick={() => handleEventContainerTagging.handleChange(true)}
            >
              {i18n.t('tagging.eventContainer.taggingBtn')}
            </div>
            <div
              className={!handleEventContainerTagging.value ? 'switch-btn active' : 'switch-btn inactive'}
              onClick={() => handleEventContainerTagging.handleChange(false)}
            >
              {i18n.t('tagging.eventContainer.eventList')}
            </div>
          </div>
        )}

        <div className='conatiner-content'>
          {handleEventContainerTagging.value ? (
            <>
              <div className='content-top'>
                {this.props.children ? null : (
                  <TimeMenu
                    tags={this.props.tags}
                    postTag={this.props.createTag}
                    playerCurrentTime={this.props.playerCurrentTime}
                    videoType={this.props.videoType}
                    timerInformation={this.props.timerInformation}
                    handleStartStopPlayer={this.props.handleStartStopPlayer}
                    liveTagging={this.props.liveTagging}
                  />
                )}

                <ClubScoreInfo
                  clubs={this.props.clubs}
                  score={this.props.score}
                  videoInfo={this.props.videoInfo}
                  scrollTop={this.state.scrollTop}
                  timerInformation={this.props.liveTagging ? this.props.timerInformation : undefined}
                  videoType={this.props.videoType}
                  tags={this.props.tags}
                />
              </div>

              <div className='content-bottom'>
                {this.props.children ? (
                  this.props.children
                ) : (
                  <>
                    <div style={{ height: 4 }}></div>
                    <div className='btn-container' onScroll={(event) => this.onScroll(event)}>
                      {this.renderEvents()}
                    </div>
                  </>
                )}
              </div>

              {/*this.props.children || this.props.liveTagging ? null : (
                <div className='insert-button' onClick={() => this.props.handleOpenCloseInsert()}>
                  <div className='text'>{i18n.t('tagging.eventContainer.showInsert')}</div>
                  <div className='icon'>
                    <img src={insertIcon} />
                  </div>
                </div>
              )*/}
            </>
          ) : (
            <EventList
              tags={this.props.tags}
              postTag={this.props.postTag}
              playerCurrentTime={this.props.playerCurrentTime}
              videoType={this.props.videoType}
              clubs={this.props.clubs}
              handleSeekTo={this.props.handleSeekTo}
              reloadTags={this.props.reloadTags}
            />
          )}

          <div style={{ position: 'relative' }}>{this.props.popupJSX()}</div>
        </div>
      </div>
    )
  }
}

export default withRouter(EventContainer)
