import React, { useState } from 'react'
import i18n from 'i18next'

import StaigeTooltip from '../../../../components/styles/Tooltip'
import StaigeRadioButton from '../../../../components/styles/RadioButton'

import './TeamSideChoise.scss'

export const TeamSideChoise = (props: {
  clubALeft: boolean | undefined
  setClubALeft: (homeSideLeft: boolean | undefined) => void
}) => {
  const [homeSideLeft, setHomeSideLeft] = useState(props.clubALeft)
  const setPlayedSide = (side: boolean | undefined) => {
    setHomeSideLeft(side)
    props.setClubALeft(side)
  }

  return (
    <div className='teamSideChoise'>
      <div className='teamSideChoise-header'>
        <span>{i18n.t('recordingPlan.teams.teamSideChoise.header.title')}</span>
        <StaigeTooltip
          title={i18n.t('recordingPlan.teams.teamSideChoise.header.tooltipTitle')}
          text={i18n.t('recordingPlan.teams.teamSideChoise.header.tooltipText')}
          style={{ fontSize: 14, marginLeft: 4 }}
        />
      </div>
      <div className='teamSideChoise-body'>
        <StaigeRadioButton
          label={i18n.t('recordingPlan.teams.teamSideChoise.unknown')}
          handleClick={() => {
            setPlayedSide(undefined)
          }}
          checked={homeSideLeft === undefined}
        />
        <StaigeRadioButton
          label={i18n.t('recordingPlan.teams.teamSideChoise.leftSide')}
          handleClick={() => {
            setPlayedSide(true)
          }}
          checked={homeSideLeft === true}
        />
        <StaigeRadioButton
          label={i18n.t('recordingPlan.teams.teamSideChoise.rightSide')}
          handleClick={() => {
            setPlayedSide(false)
          }}
          checked={homeSideLeft === false}
        />
      </div>
    </div>
  )
}
