import React from 'react'

import './RadioButton.scss'

type staigeRadioButtonPropTypes = {
  label?: string
  handleClick?: (value: boolean) => void
  checked?: boolean
}

type staigeRadioButtonStateTypes = {
  checked: boolean
}

export default class StaigeRadioButton extends React.Component<
  staigeRadioButtonPropTypes,
  staigeRadioButtonStateTypes
> {
  constructor(props: staigeRadioButtonPropTypes) {
    super(props)

    this.state = {
      checked: false
    }
  }

  componentDidMount(): void {
    if (this.props.checked !== undefined) {
      this.setState({
        checked: this.props.checked
      })
    }
  }

  componentDidUpdate(prevProps: Readonly<staigeRadioButtonPropTypes>): void {
    if (this.props.checked !== undefined && prevProps !== undefined) {
      if (this.props.checked !== prevProps.checked) {
        this.setState({
          checked: this.props.checked
        })
      }
    }
  }

  handleCheck = () => {
    const { handleClick } = this.props
    if (handleClick) {
      handleClick(!this.state.checked)
    }
  }

  render() {
    const { label } = this.props
    const { checked } = this.state

    return (
      <div className={`staige-radioButton container`}>
        <input
          type='radio'
          checked={checked}
          onClick={() => this.handleCheck()}
          readOnly={this.props.checked === undefined}
        />
        <label>{label}</label>
      </div>
    )
  }
}
